.qty-stepper {
  height: 100%;
  color: #6b93a7;
  width: 20%;
}

.qty-stepper:hover {
  color: #c58940;
}

.qty-stepper.up {
  margin-left: 5px;
}

.qty-stepper.down {
  margin-right: 5px;
}

.qty-span {
  width: 40%;
  display: inline-block;
  text-align: center;
  font-size: 20px;
  line-height: 1;
  color: #084b6d;
}

.qty-container {
  border-radius: 5px;
  background-color: #f3f3f3;
  max-width: 100px;
  width: 100%;
  height: 60px;
  text-align: center;
  font-size: 20px;
  line-height: 1;
  color: #084b6d;
}

.order-item {
  border: 1px solid #c58940;
  border-radius: 8px;
  margin-bottom: 5px;
}

.order-item-title {
  padding: 20px 5px;
  background-color: #faf8f1;
  border-radius: 7px 7px 0px 0px;
}

.order-item-footer {
  padding: 20px 5px;
  background-color: #faf8f1;
  border-radius: 0px 0px 7px 7px;
}

.order-item-status {
  padding: 2px 5px;
  border-radius: 5px;
  color: #faf8f1;
  font-weight: bold;
}

.img-product {
  height: 250px;
}
