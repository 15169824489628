button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .on {
    color: gold;
  }
  .off {
    color: #ccc;
  }