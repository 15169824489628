.center{
    text-align: center;
}

.opaque{
    opacity: 1;
}

.main{
    position: relative;
	overflow: hidden;
	min-height: 100vh;
}